<template>
  <!--begin::Dashboard-->
  <div class="p-5 container" :key="componentKey">
    <div class="row mb-5" style="padding: 2vh;">
      <!-- <div style="position: relative;">
        <el-progress type="circle" :percentage="90" status="success" />
        <p style="position: absolute;display: flex;top: 40px;left: 55px;">
          100%
        </p>
      </div> -->

      <div class="col-12 mt-5" v-if="isCameraOpen == true">
        <video
          v-if="isCameraOpen == true"
          ref="video"
          id="video"
          style="width: 200px;height:auto;"
          autoplay
        ></video>
        <canvas
          style="display: none;"
          ref="canvas"
          id="canvas"
          width="640"
          height="480"
        ></canvas>
      </div>
      <div
        class="col-12 col-lg-6"
        style="font-style: normal;
                font-weight: 500;
                font-size: 30px;
                line-height: 39px;
                color: #000000;"
      >
        {{ chapterName }}
        <!-- {{ answersList }} -->
      </div>
      <div
        class="col-12 col-lg-6"
        style="display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: space-around;"
      >
        <span
          style="font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #000000;"
        >
          คำถามข้อที่
          <span style="color:#F2A17D">{{ page + 1 }}</span> /
          {{ listData ? listData.length : "0" }}</span
        >

        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_answers"
          class="btn btn-info"
        >
          กระดาษคำตอบ
        </button>
        <span
          style="font-weight: 400;
                  font-size: 20px;
                  line-height: 26px;
                  color: #000000;"
        >
          <i class="fa fa-clock"></i> {{ countdown }}</span
        >
      </div>
      <div
        class="col-12 mt-5"
        v-if="listData"
        style="background: #F7F7F7;
                border: 1px solid #D8D8D8;
                border-radius: 15px;
                padding: 20px;"
      >
        <div class="row" style="position: relative;">
          <div class="col-12 col-lg-8 offset-lg-2">
            <!-- <div v-for="(item, index) in captures" :key="index">
            <img v-bind:src="item" style="width: 100%" />
          </div> -->
            <p
              style="font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 23px;"
            >
              {{ listData[page].textQuestion }}
            </p>
            <img
              v-if="listData[page].imageQuestion"
              :src="imageUrl + listData[page].imageQuestion"
              alt=""
              class="w-100"
            />

            <div class="row" v-if="listData[page].qType == 1">
              <div
                class="col-12 col-md-6 p-5"
                v-for="(itemA, indexA) in listData[page].questionChoices"
                :key="indexA"
              >
                <div
                  @click="setAnswer(listData[page].id, itemA.choiceId, 1, page)"
                  style="background: #FFFFFF;
                        border-radius: 10px;
                        height: 100%;
                        padding: 20px;"
                >
                  <img
                    v-if="itemA.imageChoice"
                    :src="imageUrl + itemA.imageChoice"
                    class="w-100"
                    alt=""
                  />
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid my-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      :name="'qId' + listData[page].id"
                      :value="itemA.choiceId"
                      :id="'qaId' + itemA.choiceId"
                      @click="
                        setAnswer(listData[page].id, itemA.choiceId, 1, page)
                      "
                      v-model="answer[page]"
                    />
                    <label
                      class="form-check-label"
                      :for="'qaId' + itemA.choiceId"
                    >
                      {{ itemA.textChoice }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="my-5">
              <input
                type="text"
                v-model="answer[page]"
                class="form-control"
                @change="setAnswer(listData[page].id, answer[page], 2, page)"
              />
            </div>
            <div style="display: flex;justify-content: space-between;">
              <button
                type="button"
                @click="previousPage()"
                :disabled="page == 0"
                class="btn btn-exam"
              >
                ก่อนหน้า
              </button>
              <button
                v-if="page == listData.length - 1"
                 :disabled="submitButton"
                type="button"
                @click="submit()"
                class="btn btn-exam"
              >
                ส่งคำตอบ
              </button>
              <button
                v-else
                type="button"
                @click="nextPage()"
                class="btn btn-exam"
              >
                ถัดไป
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="kt_modal_answers"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_answers_header">
          <!--begin::Modal title-->
          <h2>กระดาษคำตอบ</h2>
          <!-- {{answer}} -->
          <!--end::Modal title-->
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body py-10 px-lg-17" v-if="listData">
          <div
            v-for="(item, index) in listData"
            :key="index"
            :id="item.qaId"
            class="subject "
          >
            <span
              ><button
                class="btn btn-sm w-80px my-2 btn-primary"
                @click="changePage(index)"
              >
                ข้อ {{ index + 1 }}.</button
              >&nbsp;&nbsp;&nbsp;
              <span v-if="listData[index].qType == 1">
                <span
                  v-for="(item, indexA) in item.questionChoices"
                  :key="indexA"
                  :class="
                    item.choiceId == answer[index]
                      ? 'choice green'
                      : 'choice grey'
                  "
                  >{{ indexA + 1 }}</span
                >
              </span>
              <span v-else class="mx-5">
                {{ answer[index] }}
              </span>
            </span>
          </div>
        </div>
        <div class="modal-footer flex-center"></div>
      </div>
    </div>
  </div>
  <!-- dialog score -->
  <!-- <el-button text @click="dialogVisible = true">
    click to open the Dialog
  </el-button> -->

  <el-dialog
    v-model="dialogVisible"
    title=""
    width="30%"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div style="text-align: center;">
      <strong v-if="currentPercent >= percentPass"
        >ส่งคำตอบเรียบร้อยแล้ว</strong
      >
      <strong v-else>เสียใจด้วยคุณไม่ผ่านการทดสอบ</strong>

      คะแนนของคุณคือ<br />
      <span class="score_big">{{ currentScoreUser }}</span> /
      {{ currentScore }} คะแนน<br />
      <div style="position: relative;">
        <el-progress
          type="circle"
          :percentage="currentPercent"
          :color="customColorMethod"
        />
      </div>
      <p
        v-if="currentPercent < percentPass && currentRound == 2"
        class="text-danger"
      >
        ระบบจะทำการรีเซ็ตข้อมูลผู้เรียนใหม่อีกครั้ง
      </p>
      <el-button class="mt-5" type="primary" @click="onBack()">
        ตกลง
      </el-button>
    </div>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
const customColorMethod = (percentage: number) => {
  if (percentage < 60) {
    return "#f56c6c";
  }
  return "#67c23a";
};
const dialogVisible = ref(false);
import jQuery from "jquery";
const $ = jQuery;
const video = ref();
const canvas = ref();
const captures: any = ref([]);
const isCameraOpen = ref(false);
const listData = ref();
const page = ref(0);
const score = ref(0);
const answer = ref([] as Array<object>);
const answersList = ref([
  {
    questionId: "",
    choiceId: [],
    answerUser: "",
  },
]);
import router from "@/router";
const submitButton =ref(false)
export default defineComponent({
  name: "blog-detail",
  components: {},
  data() {
    return {
      checkPermission: 0,
      timeInSecs: 0,
      ticker: 0,
      imageUrl: process.env.VUE_APP_IMG_URL,
      countdown: "",
      dataParams: this.$route.params,
      // eslint-disable-next-line no-undef
      localStream: {
        srcObject: "",
      },
      componentKey: 0,
      percentPass: 60,
      currentScore: 0,
      currentScoreUser: 0,
      currentPercent: 0,
      currentRound: 0,
      chapterName: "",
    };
  },
  setup() {
    /* eslint-disable */
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      // if (localStorage.getItem("answer")) {
      //   answer.value = JSON.parse(localStorage.getItem("answer")!);
      // }
      answersList.value = [];
      // console.log(answer.value);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = async () => {
      const getSyllabus = await axios.get(
        process.env.VUE_APP_API_URL + "/getSyllabus?id=" + route.params.sbId,
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      const getCourse = await axios.get(
        process.env.VUE_APP_API_URL + "/getCourse?id=" + route.params.cId,
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExamQuestion?examId=" +
            route.params.exId,
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          listData.value = response.data.data;
          // console.log(getSyllabus.data.data.content);

          setCurrentPageBreadcrumbs(getCourse.data.data.content[0].title, [
            getSyllabus.data.data.content[0].name,
          ]);
        })
        .catch((error) => {
          // console.log(error);
        });
      if (isCameraOpen.value == false) {
        // console.log(video.value);
      }
    };
    // const register = () => {
    //   router.push({ name: "mandatory-learn", params: { id: route.params.id } });
    // };
    const previousPage = () => {
      page.value = page.value - 1;
    };
    const nextPage = () => {
      page.value = page.value + 1;
    };
    const changePage = (event) => {
      page.value = event;
    };
    const setAnswer = (qId, dataAnswer, type, page) => {
      const list: any = {};
      if (answersList.value.length > 0) {
        // console.log(answersList.value);
        if (
          setDataInArrayObjectById(answersList.value, qId, dataAnswer, type) ==
          false
        ) {
          if (type == 1) {
            answer.value[page] = dataAnswer;
            list.questionId = qId;
            list.choiceId = [dataAnswer];
            list.answerUser = "";
          } else {
            list.questionId = qId;
            list.choiceId = [];
            list.answerUser = dataAnswer;
          }
        } else {
          for (const loopAnswer of answersList.value) {
            let data: any = [];
            data.push(dataAnswer);
            // console.log(dataAnswer);
            if (loopAnswer.questionId == qId) {
              if (type == 1) {
                answer.value[page] = dataAnswer;
                loopAnswer.choiceId = data;
                loopAnswer.answerUser = "";
              } else {
                loopAnswer.choiceId = [];
                loopAnswer.answerUser = dataAnswer;
              }
            }
          }
        }
      } else {
        if (type == 1) {
          answer.value[page] = dataAnswer;
          list.questionId = qId;
          list.choiceId = [dataAnswer];
          list.answerUser = "";
        } else {
          list.questionId = qId;
          list.choiceId = [];
          list.answerUser = dataAnswer;
        }
      }
      if (list.questionId) {
        answersList.value.push(list);
      }

      // localStorage.setItem("qId" + qId, qaId);
    };
    const setDataInArrayObjectById = (array, id, answer, type) => {
      var foundObject = array.find((obj) => obj.questionId === id);
      if (!foundObject) {
        return false;
      }
    };
    return {
      getDataList,
      listData,
      // register,
      page,
      previousPage,
      nextPage,
      answer,
      changePage,
      setAnswer,
      video,
      canvas,
      captures,
      isCameraOpen,
      route,
      answersList,
      setDataInArrayObjectById,
      dialogVisible,
      customColorMethod,
      submitButton
    };
  },
  async mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
      this.componentKey += 1;
      page.value = 0;
    }
    let exam = await axios.get(
      process.env.VUE_APP_API_URL + "/getExam?id=" + this.$route.params.exId,
      {
        headers: {
          token: localStorage.getItem("id_token"),
        },
      }
    );
    // this.createCameraElement();
    this.percentPass = exam.data.data.content[0].percentPass;
    let courseSection = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getCourseSection?id=" +
        this.$route.params.secId,
      {
        headers: {
          token: localStorage.getItem("id_token"),
        },
      }
    );
    for (const loopChapter of courseSection.data.data.content[0].chapters) {
      if (loopChapter.chapterId == this.$route.params.chId) {
        this.chapterName = loopChapter.titleChapter;
      }
    }
    this.startTimer(exam.data.data.content[0].limitTime * 60);
  },
  methods: {
    todo() {
      isCameraOpen.value = true;
      this.createCameraElement();
      window.setInterval(() => {
        this.capture();
      }, 2000); // interval set to one sec.
    },
    checkCamera() {
      this.checkPermission = window.setInterval(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then((stream) => {
              // console.log(stream);
            })
            .catch((error) => {
              clearInterval(this.checkPermission);
              // console.log(error);
              Swal.fire({
                title: "กรุณาให้สิทธิ webcam ในการทำข้อสอบ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#323563",
                cancelButtonColor: "#d33",
                confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
                allowOutsideClick: false,
                cancelButtonText: "กลับสู่หน้าหลัก",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.createCameraElement();
                } else {
                  router.push({ name: "mandatory-learn" });
                }
              });
            });
        }
      }, 2000);
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    toggleCamera() {
      if (isCameraOpen.value) {
        isCameraOpen.value = false;
        this.stopCameraStream();
      } else {
        isCameraOpen.value = true;
        this.createCameraElement();
      }
      //  createCameraElement();
      //  stopCameraStream();
    },
    createCameraElement() {
      isCameraOpen.value = true;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            video.value.srcObject = stream;
            video.value.play();
            this.startTimer(5 * 60);
            this.checkCamera();
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "กรุณาให้สิทธิ webcam ในการทำข้อสอบ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#323563",
              cancelButtonColor: "#d33",
              confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
              allowOutsideClick: false,
              cancelButtonText: "กลับสู่หน้าหลัก",
            }).then((result) => {
              if (result.isConfirmed) {
                this.createCameraElement();
              } else {
                router.push({ name: "mandatory-learn" });
              }
            });
          });
      }
    },
    stopCameraStream() {
      video.value = video.value.srcObject.getTracks();
      video.value.forEach((track) => {
        track.stop();
      });
    },
    capture() {
      /* eslint-disable */
      canvas.value = this.$refs.canvas;
      var context: any = canvas.value
        .getContext("2d")
        .drawImage(this.video, 0, 0, 640, 480);
      // console.log(canvas.value.toDataURL("image/png"));
      captures.value.push(canvas.value.toDataURL("image/png"));
      // this.captures.push(canvas.value.toDataURL("image/png"));
      /* eslint-disable */
    },
    startTimer(secs) {
      this.timeInSecs = parseInt(secs);
      this.ticker = window.setInterval(() => {
        this.tick();
      }, 1000);
    },
    tick() {
      var secs = this.timeInSecs;
      if (secs > 0) {
        this.timeInSecs--;
      } else {
        clearInterval(this.ticker);
        Swal.fire({
          title: "หมดเวลาทำข้อสอบแล้ว!",
          text: "ต้องการส่งคำตอบหรือไม่ ?",
          icon: "error",
          buttonsStyling: false,
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: "ส่งข้อสอบ",
          cancelButtonText: "ยกเลิก",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
            cancelButton: "btn fw-semobold btn-light-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.submit();
          } else {
            this.$router.go(-1);
          }
        });
      }
      var mins = Math.floor(secs / 60);
      secs %= 60;
      var pretty =
        (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "") + secs;
      this.countdown = pretty;
      localStorage.setItem("timer", secs + "");
    },
    percentageToDegrees(percentage) {
      return (percentage / 100) * 360;
    },
    async submit() {
      submitButton.value = true
      Swal.fire({
        title: "ต้องการส่งคำตอบใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#323563",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        allowOutsideClick: false,
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          clearInterval(this.ticker);
          clearInterval(this.checkPermission);
          let examId = this.$route.params.exId;
          let courseRegisterId = await this.getCourseRegister(
            this.$route.params.cId
          );
          // console.log(courseRegisterId);
          score.value = 0;
          axios
            .post(
              process.env.VUE_APP_API_URL + "/checkAnswer",
              answersList.value,
              {
                headers: {
                  token: localStorage.getItem("id_token")
                },
              }
            )
            .then((res) => {
              score.value = res.data.data.score;
              const formData = new FormData();
              formData.append("score", score.value + "");
              formData.append("examId", examId + "");
              formData.append("courseRegisterId", courseRegisterId + "");
              formData.append(
                "flgPretest",
                this.$route.params.type == "pretest" ? "true" : "false"
              );
              axios
                .post(process.env.VUE_APP_API_URL + "/updateScore", formData, {
                  headers: {
                    token: localStorage.getItem("id_token")
                  },
                })
                .then(async (res) => {
                  if (res.data.code == 200) {
                    Swal.close();
                    let percent = await this.formatNumber(
                      (score.value / listData.value.length) * 100
                    );
                    this.currentScore = listData.value.length;
                    this.currentScoreUser = score.value;
                    this.currentPercent = percent;
                    this.currentRound = res.data.data.round;
                    if (percent >= this.percentPass) {
                      dialogVisible.value = true;
                      answer.value = [];
                      await this.updateProgress();
                    } else {
                      dialogVisible.value = true;
                      answer.value = [];
                      if (this.$route.params.type == "pretest") {
                        await this.updateProgress();
                      }
                    }
                  } else {
                    Swal.fire({
                      title: "แจ้งเตือนข้อผิดพลาด",
                      text: res.data.message,
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                      customClass: {
                        confirmButton: "btn fw-semobold btn-light-danger",
                      },
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    title: "แจ้งเตือนข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                });
            })
            .catch((error) => {
              // console.log(error);
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: error.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      });
    },
    async getCourseRegister(courseId) {
      let courseRegisterId = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?courseId=" +
          courseId +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
          },
        }
      );
      // console.log(courseRegisterId.data.data);
      return courseRegisterId.data.data.content[0].id;
    },
    async updateProgress() {
      const formData = new FormData();
      formData.append("courseId", this.$route.params.cId + "");
      formData.append("sectionId", this.$route.params.secId + "");
      formData.append("chapterId", this.$route.params.chId + "");
      // console.log("update");
      await axios
        .post(process.env.VUE_APP_API_URL + "/updateProgress", formData, {
          headers: {
            token: localStorage.getItem("id_token")
          },
        })
        .then((res) => {
          // console.log(res);
          // router.go(-1);
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    },
    formatNumber(score) {
      return parseInt(score);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    onBack() {
      submitButton.value=false
      dialogVisible.value = false;
      router.go(-1);
    },
  },

  beforeUnmount() {
    localStorage.setItem("reloaded", "1");
    clearInterval(this.ticker);
  },
});
</script>
<style>
span.green {
  background: #5ea226;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-left: 35px;
  text-align: center;
  width: 1.6em;
}
span.grey {
  background: #cccccc;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-left: 35px;
  text-align: center;
  width: 1.6em;
}

.btn-exam {
  color: #109448;
  background: #ffffff;
  border: 1px solid #109448 !important;
  width: 160px;
  border-radius: 10px;
}
.btn-exam:hover {
  color: #ffffff;
  background: #109448;
  border-radius: 10px;
}
.btn-exam:disabled {
  background: #e5e5e5;
  color: #979797;
  border: 1px solid #d8d8d8 !important;
  border-radius: 10px;
}
.btn-register {
  background: #f2a17d;
  color: #ffffff;
  width: 70%;
  border-radius: 21px;
}
</style>
